<script setup>
</script>

<template>

	<router-view />

</template>

<style>

body {
	padding: 0;
	margin: 0;
}


</style>
