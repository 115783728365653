import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
	history: createWebHistory(process.env.VUE_APP_BASE_URL),
	routes: [
		{
			path: '/',
			name: 'Index',
			component: () => import('../views/home/HomeIndex'),
			meta: { title: '首页'}
		},
		{
			path: '/batch-trans',
			name: '批量转账',
			component: () => import('../views/token/BatchTrans.vue'),
			meta: { title: '批量转账'}
		},
		{
			path: '/batch-collect',
			name: '批量归集',
			component: () => import('../views/token/BatchCollect.vue'),
			meta: { title: '批量归集'}
		},
		{
			path: '/batch-swap',
			name: '批量交易',
			component: () => import('../views/swap/BatchSwap.vue'),
			meta: { title: '批量交易'}
		}
	]
})

router.beforeEach((to,from,next)=>{
	next()
})
export default router
